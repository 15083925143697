(function($) {
    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', "#layout_header"],
            doScrollingRightAway: false,
            animateScroll: false
        });

        swup.on('clickLink', function (e) {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $('html').css('overflow','visible');

            // if ($(e.delegateTarget).hasClass("elm_header_logo")) {
            //     $("#layout_header").find(".elm_header_nav").find(".part_ui_link").removeClass("state--active");
            // }

            // $("#layout_visual_placeholder").removeClass("is--visible");
        });

        swup.on('contentReplaced', function () {
            // let logo = $("#layout_header").find(".elm_header_logo");
            //
            // if (window.document.location.pathname === "/") {
            //     logo.attr("data-href",logo.attr("href")).removeAttr("href");
            // } else {
            //     logo.attr("href",logo.attr("data-href")).removeAttr("data-href");
            // }

            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",key)
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",key)
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",key)
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });

                // if ($(".comp_visual").length) {
                //     $("#layout_visual_placeholder").addClass("is--visible");
                //     $("#layout_header").removeClass("type--header-normal");
                // } else {
                //     $("#layout_visual_placeholder").removeClass("is--visible");
                //     $("#layout_header").addClass("type--header-normal");
                // }
            });

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }

            $(".comp_visual_lights svg").find("[data-href]").each(function(){
                $(this).attr("xlink:href", $(this).data("href"));
            });
        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_visual_scroll", "[data-visual-scroll]", function(selector) {

        selector.on("click", function(){
            let offset = 0;
            if (win.width() > 1200) {
                offset = 80;
            }
            $('html, body').animate({
                scrollTop: selector.closest(`[class^="comp_"]`).next().offset().top - offset
            }, 1000);
        });

    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
            selector.lui_ripple();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        $.lib_flickity(function(){
            selector.each(function(){
                let elm = $(this);
                let options = elm.data("lib-flickity");
                let nav = elm.parent().find("[data-lib-flickity-nav]");


                elm.on("ready.flickity", function(e){
                    elm.find(".flickity-button").lui_ripple();
                    if (elm.find(".flickity-button[disabled]").length === 2) {
                        elm.addClass("flickity-nav-disabled");
                    }
                });

                let slider = $(this).flickity({
                    groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                    cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                    setGallerySize: true,
                    autoPlay: options["autoplay"],
                    pageDots: true,
                    wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                    contain: true,
                    adaptiveHeight: true,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true
                });

                if (typeof options["flex"] !== "undefined") {
                    elm.addClass("flickity-flex").data("flickity").cells.forEach( function( cell ) {
                        cell.element.style.position = '';
                        cell.element.style.left = '';
                    });
                }

                if (nav.length) {
                    nav.on("click", function(){
                        let direction = $(this).data("lib-flickity-nav");

                        if (direction === "prev") {
                            slider.flickity('previous');
                        } else {
                            slider.flickity('next');
                        }
                    });
                }
            });
        });
    });

    $.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--fancybox-item").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    $(selector).each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });

                    $("[data-lib-fancybox-open]").on("click", function(){
                        let id = $(this).data("lib-fancybox-open");
                        $(`[data-lib-fancybox="${id}"] .col:first-of-type .lib--fancybox-item`).trigger("click")
                    })
                });
            }
        });
    });

    $.lui("lib_parallax_scene", "[data-lib-parallax-scene]", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.parallax, function() {
                bodyLoaded(function(){
                    selector.each(function(){
                        new Parallax($(this)[0]);
                    });
                });
            });
        }
    });

    $.lui("lib_tooltip", "[data-lib-tooltip]", function(selector){
        if (!$(".part_tooltip").length) {
            body.append(`<div class="part_tooltip"><strong class="part_ui_heading size--custom"></strong></div>`)
        }

        let tooltip = $(".part_tooltip");

        window.onmousemove = function (e) {
            let x = (e.clientX + 20) + 'px',
                y = (e.clientY + 20) + 'px';
            tooltip.css("top",y).css("left",x);
        };

        selector.hover(function(){
            let elm = $(this);
            tooltip.find(".part_ui_heading").text(elm.data("lib-tooltip"));
            tooltip.show();
        }, function() {
            tooltip.hide();
        });
    });

    setTimeout(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('[type=submit]').prop('disabled', true);
            frm.find('[type=submit]').addClass('state--loading');

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload, function(){
                        ScrollReveal().sync();
                    });
                }
            }).always(function(){
                frm.find('[type=submit]').prop('disabled', false);
                frm.find('[type=submit]').removeClass('state--loading');
            })
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    if (('ontouchstart' in document.documentElement)) {
        html.addClass("is-touch");
    }
})(jQuery);