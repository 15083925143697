$.lui("comp", ".comp_visual_lights", function(selector){
    let svg = selector.find("svg");

    win.on("load", function () {
        if (win.width() > 960) {
            svg.find("[data-href]").each(function () {
                $(this).attr("xlink:href", $(this).data("href"));
            });
        } else {
            win.on("resize", function () {
                if (win.width() > 960) {
                    svg.find("[data-href]").each(function () {
                        $(this).attr("xlink:href", $(this).data("href"));
                    });
                }
            });
        }
    });
    
    svg.find("[data-toggle-light]").on("click", function(){
        let elm = $(this);
        let name = elm.data("name");
        let btn = $("#btn");
        let img = $("#img");

        if (win.width() < 960) {
            return false;
        }

        if (elm.hasClass("state--active")) {
            if (name !== 3) {
                img.find('[data-name]').removeClass("state--active").filter('#OFF').addClass("state--active");
                btn.find('[data-name]').removeClass("state--active");
            }
            elm.removeClass("state--active");
            $(".elm_svg_toggle").removeClass("state--active");
        } else {
            if (name === 1) {
                img.find('[data-name]').removeClass("state--active").filter('[data-name="1-on"]').addClass("state--active");
                btn.find('[data-name]').removeClass("state--active").filter('[data-name="1"]').addClass("state--active");
            }

            if (name === 2) {
                img.find('[data-name]').removeClass("state--active").filter('[data-name="2-on"]').addClass("state--active");
                btn.find('[data-name]').removeClass("state--active").filter('[data-name="2"]').addClass("state--active");
            }

            if (name === 3) {
                $(".elm_svg_toggle").addClass("state--active");
            } else {
                $(".elm_svg_toggle").removeClass("state--active");
            }

            if (name === 2200) {
                $(".elm_svg_toggle").removeClass("state--active");
                img.find('[data-name]').removeClass("state--active").filter('[data-name="3-on1"]').addClass("state--active");
                btn.find('[data-name]').removeClass("state--active").filter('[data-name="3"]').addClass("state--active");
            }

            if (name === 4000) {
                $(".elm_svg_toggle").removeClass("state--active");
                img.find('[data-name]').removeClass("state--active").filter('[data-name="3-on2"]').addClass("state--active");
                btn.find('[data-name]').removeClass("state--active").filter('[data-name="3"]').addClass("state--active");
            }


            if (name !== 3) {
                svg.find("[data-toggle-light]").removeClass("state--active");
                elm.addClass("state--active");
            }
        }
    });
});