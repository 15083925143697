$.fn.lui_ripple = function () {
    $(this).each(function(){
        if (!$(this).hasClass("no--ripple")) {
            $(this).attr("data-lib-ripple", "");
        }
    });
    doc.on("click", "[data-lib-ripple]", function(event){
        let surface = $(this);

        if (surface.find(".lib--ripple-inner").length === 0) {
            surface.prepend("<div class='lib--ripple-inner'></div>");
        }

        let ink = surface.find(".lib--ripple-inner");

        ink.removeClass("animated");

        if (!ink.height() && !ink.width()) {
            let d = Math.max(surface.outerWidth(), surface.outerHeight());
            ink.css({height: d, width: d});
        }

        let x = event.pageX - surface.offset().left - (ink.width() / 2),
            y = event.pageY - surface.offset().top - (ink.height() / 2),
            rippleColor = surface.data("lib-ripple-color");

        ink.css({
            top: y + 'px',
            left: x + 'px',
            background: rippleColor
        }).addClass("animated");
    });
};