$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    // header.find(".elm_header_nav").on("click", ".part_ui_link", function(){
    //     header.find(".elm_header_nav").find(".part_ui_link").removeClass("state--active");
    //     $(this).addClass("state--active");
    // });

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
    }

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });
});

doc.on("input", "[data-search-autocomplete]", function () {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    let results_data = header_search_results.find("[data-results-product]");
    let query = $(this).val();

    results_data.html("");
    header_search.find(".icon--search").addClass("state--loading");
    $.ajax({
        method: 'post',
        dataType: 'json',
        data: {do: 'search', q: query},
        cache: false
    }).done(function (data) {
        results_data.html("");
        header_search_results.fadeIn(300);
        header_search.find(".icon--search").removeClass("state--loading");
        if (typeof data.products === 'undefined') {
            results_data.append(`
                        <div class="col">
                            <div class="part_ui_title" style="padding: 2rem;">${data.lng_not_found}</div>
                        </div>
                    `)
        } else {
            data.products.forEach(function (item) {
                results_data.append(`
                            <div class="col col--6 col--4-t col--2-d" data-search-item>
                                <a href="${item.uri}" class="part_item_products" title="">
                                    <div class="wrp_item_image">
                                        <div class="part_ui_image background--transparent" data-ratio="1/1">
                                            <img src="${item.image}" alt="" />
                                        </div>
                                    </div>
                                    <div class="wrp_item_title">
                                        <h3 class="part_ui_heading size--small">${item.name}</h3>
                                    </div>
                                </a>
                            </div>
                        `)
            });

            // if (data.hasOwnProperty("category")) {
            //     results_data.append(`
            //                 <div class="col col--6 col--4-t col--2-d">
            //                     <a href="${data.category.uri}" class="part_item_category" title="">
            //                         <div class="wrp_item_image">
            //                             <div class="part_ui_image background--transparent" data-ratio="1/1">
            //                                 <img src="${data.category.image}" alt="" />
            //                             </div>
            //                         </div>
            //                         <div class="wrp_item_title">
            //                             <h3 class="part_ui_heading size--small">${data.category.name}</h3>
            //                         </div>
            //                     </a>
            //                 </div>
            //             `);
            // }
        }

        if (data.total > 5) {
            $('[data-results-product-all]').find('span').text(data.total);
            $('[data-results-product-all]').show();
        } else {
            $('[data-results-product-all]').hide();
        }
    });
});

doc.on("keypress", "[data-search-autocomplete]", function (e) {
    if (e.which === 13) {
        let $elm = $(this);
        let totalFound = $('[data-results-product]').find('[data-search-item]').length;

        if (totalFound === 1) {
            window.location.href = $('[data-results-product] [data-search-item] a').attr('href');
        } else if (totalFound > 1) {
            let url = $('#layout_header .elm_header_nav a').first().attr('href');
            window.location.href = url + '?q=' + $elm.val() + '#block-products';
        }
    }
});

doc.on("click", "[data-toggle-search]", function () {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    header_search.fadeIn(300);
    header_search.find('input[type="text"]').focus();

    html.on("click", function (e) {
        if ($(e.target).closest('#layout_header').length === 0) {
            header_search.fadeOut(300);
            header_search.find('input[type="text"]').val("").blur();
            header_search_results.fadeOut();
            html.off('click');
        }
    });
});

doc.on("click", "[data-close-search]", function (e) {
    let header_search = doc.find("#layout_header").find(".wrp_header_search"),
        header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

    e.preventDefault();
    header_search.fadeOut(300);
    header_search.find('input[type="text"]').val("").blur();
    header_search_results.fadeOut();
    html.off('click');
});