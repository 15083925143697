$.lui("part", ".part_video", function(selector){
    selector.on("click", function() {
        let elm = $(this);
        let video = elm.find("video");

        if (!elm.hasClass("state--playing")) {
            elm.addClass("state--playing");
            video.attr("controls",true)[0].play();
        }

        video.one("play", function () {
            $(this).removeClass("state--paused");
        });

        video.one("ended", function() {
            elm.removeClass("state--playing");
            video.removeClass("state--paused").removeAttr("controls").replaceWith(video.clone());
        });
    });
});