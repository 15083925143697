$.lui("comp", ".comp_features", function(selector){
    let part = selector.find(".part_item_features");

    selector.on("click", "[data-toggle-more]", function(){
        let elm = $(this);
        let part_closest = elm.closest(".part_item_features");

        let text = elm.data("toggle-more").replace(/,\s+/g,",").split(",");
        let elm_other = part.not(part_closest).find("[data-toggle-more]");

        part.find(".elm_item_text").css("max-height","");
        part.not(part_closest).removeClass("state--active").find(".text").text(text[0]);
        part.find("[data-toggle-more]").find(".icon-r").css("width", elm_other.find(".text").width() + parseInt(elm_other.find(".icon-r").css("padding-right").replace("px","")));

        if (part_closest.hasClass("state--active")) {
            part_closest.removeClass("state--active");
            elm.find(".text").text(text[0]);
            elm.find(".icon-r").css("width", elm.find(".text").width() + parseInt(elm.find(".icon-r").css("padding-right").replace("px","")))
        } else {
            part_closest.addClass("state--active");
            part_closest.find(".elm_item_text").css("max-height",part_closest.find(".elm_item_text").children().height());
            elm.find(".text").text(text[1]);
            elm.find(".icon-r").css("width", elm.find(".text").width() + parseInt(elm.find(".icon-r").css("padding-right").replace("px","")))
        }
    });

    win.on("resize", function(){
        part.filter(".state--active").find(".elm_item_text").css("max-height",part.filter(".state--active").find(".elm_item_text").children().height());
        part.find(".icon-r").css("width","");
    })
});