let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.6/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.6/dist/jquery.fancybox.min.css",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "parallax": "https://cdn.jsdelivr.net/npm/parallax-js@3.1.0/dist/parallax.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}"
};